import React from "react"
import PropTypes from "prop-types"

import { graphql } from "gatsby"
import { BlogPostGrid, ContentWrapper } from "../../components"
// import { Typography } from "@mui/material"

const BlogTemplate = ({ data }) => {
  const { blogPosts } = data
  const { title, subtitle, blog_read_more_button } =
    data.markdownRemark.frontmatter

  const posts = blogPosts.nodes

  return (
    <ContentWrapper title={title} subtitle={subtitle}>
      {/* <Typography
        color="#242424"
        fontSize="1.25rem"
        letterSpacing="0.02em"
        lineHeight="1.5rem"
        fontWeight="bold"
        pt={2}
        mb={4}
      >
        {blog_title}
      </Typography> */}
      <BlogPostGrid
        posts={posts}
        buttonText={blog_read_more_button}
        showLimitedPosts
      />
    </ContentWrapper>
  )
}

BlogTemplate.propTypes = {
  data: PropTypes.object.isRequired,
}

export default BlogTemplate

export const query = graphql`
  fragment BlogPage on MarkdownRemarkFrontmatter {
    title
    subtitle
    blog_title
    blog_read_more_button
  }
`
